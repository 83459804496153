var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-fill d-flex justify-content-center mt-4 mb-4" },
    [
      _c("MiniSpinner", { staticClass: "mr-2" }),
      _vm._v(" " + _vm._s(_vm.label ? _vm.label + "..." : "") + " ")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }